import React, {  useEffect, useState } from "react";

// Custom Components
import HeaderOne from "./components/common/headers/header-one";
import FooterOne from "./components/common/footers/footer-one";
import PushMenu from "react-push-menu";
import LinkComponent from "./lib/menuPush/LinkComponent";

import "./lib/menuPush/component.scss";

const App = ({ children }) => {
  const [menu] = useState([
    {
      name: "home",
      link: "/",
      children: [],
    },
    { name: "About Us", link: "/about-us", children: [] },
    { name: "Contact", link: "/contact-us", children: [] },
  ]);
  const [isLoading, setIsloading] = useState(true);

  const load = () => {
    setIsloading(false);
    document.querySelector(".loader-wrapper").style = "display: none";
  };
  useEffect(() => {
    load();
  }, []);

  return (
    !isLoading && (
      <div className="opal-wrapper">
        <PushMenu
          backIcon={<i className="fa fa fa-arrow-left"></i>}
          expanderComponent={() => {
            return <i className="fa fa-chevron-right"></i>;
          }}
          autoHide={true}
          nodes={{ children: menu }}
          type={"cover"}
          linkComponent={LinkComponent}
          propMap={{ url: "link" }}
        >
          <div id="page" className="site">
            <HeaderOne logoName={"logo.svg"} />
            {children}
            <FooterOne logoName={"logo.svg"} />
          </div>
        </PushMenu>
      </div>
    )
  );
};

export default App;
