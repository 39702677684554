import React, { useContext } from "react";
import { Parallax } from "react-parallax";
import { useForm } from "react-hook-form";
import Firebase from "../Firebase";
import { AuthContext } from "../../hoc/Auth";
import { Redirect } from "react-router";

const Admin = ({ history }) => {
  const defaultBg = `/assets/imagewebp/breadcrumb/breadcrumb-1.webp`;
  const { register, handleSubmit } = useForm();
  const { currentUser } = useContext(AuthContext);

  const onSubmit = (data) => {
    const test = Firebase.Persistence.LOCAL;
    Firebase.auth.setPersistence(test).then(() => {
      return Firebase.login(data.email, data.password)
        .then(() => {
          //   alert("IN !!")
          history.push("adminpanal");
        })
        .catch((err) => {
          alert(err);
        });
    });
  };
  if (currentUser) {
    return <Redirect to="adminpanal" />;
  } else {
    return (
      <div className="section-wrapper">
        <Parallax
          className="breadcrumb-section"
          bgImage={defaultBg}
          bgImageAlt="the cat"
          strength={500}
        >
          <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} className="form-group-v1">
              <div className="col col-md-12" style={{ marginBottom: "50px" }}>
                <h1 style={{ color: "white" }}>Admin Login</h1>
              </div>

              <div className="row">
                <div className="col-lg-6 ">
                  <div
                    className="form-group"
                    style={{ backgroundColor: "white" }}
                  >
                    <input
                      name="email"
                      ref={register}
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div
                    className="form-group"
                    style={{ backgroundColor: "white" }}
                  >
                    <input
                      name="password"
                      ref={register}
                      type="password"
                      placeholder="Password"
                      className="form-control"
                      required
                    />
                  </div>
                </div>
              </div>
              <button
                type="submit"
                style={{ minWidth: "250px" }}
                className="btn btn-primary mt-4"
              >
                Login
              </button>
            </form>
          </div>
        </Parallax>
      </div>
    );
  }
};

export default Admin;
