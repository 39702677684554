import app from "firebase/app";
import "firebase/auth";
import "firebase/firebase-firestore";
import firebase from "firebase";

const config = {
  apiKey: "AIzaSyC7VEGQOB4drzmYyICXIXg-5SKAdbr6XRk",
  authDomain: "realestate-5922f.firebaseapp.com",
  projectId: "realestate-5922f",
  storageBucket: "realestate-5922f.appspot.com",
  messagingSenderId: "1017378359187",
  appId: "1:1017378359187:web:fae8f2b09c7dd3de804a66",
  measurementId: "G-PVWF0QHLXE",
};

class Firebase {
  constructor() {
    if (!firebase.apps.length) {
      app.initializeApp(config);
    }
    this.auth = app.auth();
    this.db = app.firestore();
    this.Persistence = app.auth.Auth.Persistence;
  }

  login(email, password) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  logout() {
    return this.auth.signOut();
  }

  async getLeads() {
    const data = await this.db.collection("Leads").get();
    return data;
  }
  async deleteLead(id) {
    const data = await this.db.collection("Leads").doc(id);
    const doc = await data.get();
    if (!doc.exists) {
      alert("Lead already deleted!");
    } else {
      const res = await this.db
        .collection("Leads")
        .doc(id)
        .delete();
      alert("Lead Deleted!");
      window.location.reload();
    }
  }

  async createMsg(data) {
    const currentdate = new Date();
    let datetime =
      currentdate.getDate() +
      "  " +
      (currentdate.getMonth() + 1) +
      "  " +
      currentdate.getFullYear() +
      "  " +
      +currentdate.getHours() +
      ":" +
      currentdate.getMinutes() +
      ":" +
      currentdate.getSeconds();
    await this.db
      .collection("Leads")
      .doc(`lead(${datetime})`)
      .set(data);
  }
}

export default new Firebase();
