import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
// Import custom components
import SliderHome from "./common/slider/slider";
import OurServicesSection from "./sections/ourservices/ourservices";

const Home1 = () => {
  const [smallWindow, setSmallWindow] = useState(false);

  useEffect(() => {
    log();
  }, [smallWindow]);

  const log = () => {

    if (window.innerWidth <= 1050) {
      setSmallWindow(true);
    }

    window.addEventListener("resize", () => {
      if (window.innerWidth <= 750) {
        setSmallWindow(true);
      } else {
        setSmallWindow(false);
      }
    });
  };

  return (
    <div className={`home-page-wrap`}>
      <Helmet>
        <title>Legacy | Home</title>
      </Helmet>
      <div className="section-wrapper pt-0 pb-0">
        <SliderHome smallWindow={smallWindow}  />
      </div>
      <div className="section-wrapper">
        <OurServicesSection />
      </div>
    </div>
  );
};

export default Home1;
