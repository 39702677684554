import React from "react";
import Slider from "react-slick";
import "./slider.scss";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const SliderHome = ({ smallWindow }) => {
  const settings = {
    infinite: true,
    autoplay: true,
    speed: 500,
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const imgs = [
    {
      bg: "/assets/images/slider/Mview.jpg",
      logo: "/assets/images/logos/view.png",
    },
    {
      bg: "/assets/images/slider/ora.jpg",
      logo: "/assets/images/logos/ora.png",
    },
    {
      bg: "/assets/images/slider/Sodic.jpeg",
      logo: "/assets/images/logos/sodic.png",
    },
  ];
  const Slide = (bg, logo) => {
    if (!smallWindow) {
      return (
        <div className="slider-item position-relative">
          <div
            className="slider-image"
            style={{
              backgroundImage: `url(${bg})`,
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="container">
            <div className="slider-content">
              <div className="text-center w-100"></div>
              <div className="slider-footer text-left bg-primary">
                <img src={`${logo}`} alt="Logo" />
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <img src={`${bg}`} alt="bg" />
            <img  className="legend" src={`${logo}`} alt="logo" style={{ width: "50px",backgroundColor:"#cabe9f" }} />
        </div>
      );
    }
  };

  return (
    <div className={"slider-wrap position-relative"}>
      {smallWindow ? (
        <Carousel
          dynamicHeight={true}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
          autoPlay={true}
          infiniteLoop={true}
        >
          {imgs.map((slide) => Slide(slide.bg, slide.logo))}
        </Carousel>
      ) : (
        <Slider {...settings}>
          {imgs.map((slide) => Slide(slide.bg, slide.logo))}
        </Slider>
      )}
    </div>
  );
};

export default SliderHome;
