import React, { Component } from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import Helmet from "react-helmet";
import { Parallax } from "react-parallax";
import Heading from "../../common/heading/heading";
import "./about.scss";
// import OurTeamBlock from "../../common/our-team/our-team";
// import CallToActionSection from "../../sections/caltoaction/caltoaction";
// import OurTeamSection from "../../sections/our-team/our-team";

class AboutPage extends Component {
  render() {
    return (
      <div className="about-page-wrap">
        <Helmet>
          <title>Legacy | AboutPage</title>
        </Helmet>
        <Breadcrumb title={"About Us"} />

        <div className="section-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12 content-left">
                <Heading>Legacy Real Estate Consultancies</Heading>
                <p className="text-black">
                  It's a Brokerage company the core of our expertise is the
                  residential and commercial Real Estate market.
                </p>
                <p>
                  We will assist you, from viewing available properties, through
                  consultation and negotiations, to closing, and follow-up. We
                  deal with each client on a personal, customized approach in
                  accordance to your level of expertise with the purchasing
                  process and your personal tastes. We operate using a team
                  approach, which means, that you will not only have a dedicated
                  agent available but administrative support as well.
                </p>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  className="img-fluid"
                  src="/assets/imagewebp/about/banner_ab1.webp"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section-wrapper">
          <Parallax
            className={`section-parallax`}
            bgImage={`${process.env.PUBLIC_URL}/assets/imagewebp/about/bg_ab1.webp`}
            bgImageAlt="the cat"
            strength={500}
          >
            <div className="about-parallax  position-relative"></div>
          </Parallax>
        </div>
        <div className="section-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  className="img-fluid"
                  src="/assets/imagewebp/about/banner_ab2.webp"
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-right">
                <Heading>OUR SERVERS</Heading>
                <p>
                  We deal with each client on a personal, customized approach in
                  accordance to your level of expertise with the purchasing
                  process and your personal tastes. We operate using a team
                  approach, which means, that you will not only have a dedicated
                  agent available but administrative support as well. In
                  addition to searching for real estate properties, we can offer
                  the discerning client comprehensive information on the
                  purchasing process, comparable properties, market trends, area
                  communities and developments, buyer representation, purchasing
                  requirements, investor resources, and additional resources.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-wrapper">
          <Parallax
            className={`section-parallax`}
            bgImage={`${process.env.PUBLIC_URL}/assets/imagewebp/about/bg_ab1.webp`}
            bgImageAlt="the cat"
            strength={500}
          >
            <div className="about-parallax  position-relative"></div>
          </Parallax>
        </div>
        

        <div className="section-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <img
                  className="img-fluid"
                  src="/assets/imagewebp/about/banner_ab4.webp"
                  alt=""
                />
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 content-right">
                <Heading>OUR GOAL</Heading>
                <p>
                To find you the perfect home a place you can call your
                  own or any real estate that has the quilty and values you
                  desire, to make your desires our number one priority is <strong>Our Goal</strong>
                </p>
               
              </div>
            </div>
          </div>
        </div>
        
      </div>
    );
  }
}

export default AboutPage;
