import React, { useState} from 'react';
import {Link} from 'react-router-dom';

const MainMenu =()=> {

    const [page,setPage]= useState("")

        return (
            <div className="navigation-top">
                <nav className="main-navigation" aria-label="Top Menu">
                    <div className="mainmenu-container">
                        <ul className="top-menu menu">
                            <li className="menu-item">
                                <Link 
                                to='/'
                                className={ page === "" ? 'selected' : ''}
                                onClick={()=>{setPage("")}}
                                >Home</Link>    
                                </li>
                                <li className="menu-item">
                                <Link 
                                to='/contact-us' 
                                className={ page === "contact" ? 'selected' : ''}
                                onClick={()=>{setPage("contact")}}
                                >Contact</Link>    
                                </li>
                                <li className="menu-item">
                                <Link 
                                to='/about-us' 
                                className={ page === "about" ? 'selected' : ''}
                                onClick={()=>{setPage("about")}}
                                >About Us</Link>    
                                </li>
                                
                        </ul>
                    </div>
                </nav>
            </div>
        )
}


export default (MainMenu);