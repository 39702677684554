import React from "react";
import "./footer.scss";
import Copyright from "../copyright/copyright";
import ContactForm from "../contact-form/contact-form";

const FooterOne = () => {
  return (
    true && (
      <footer className="site-footer">
        {/*Section 2*/}
        <div className="section-2">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-12">
                <h6 className="heading-title">BUILDING LOCATION</h6>
                <div className="icon-list footer-address">
                  <ul>
                    <li>
                      <span className="icon-list-icon">ADDRESS:</span>
                      <span className="icon-list-text">
                        116, 1st Neighborhood, 5th Settlement, New Cairo
                      </span>
                    </li>
                    <li>
                      <span className="icon-list-icon">PHONE:</span>
                      <span className="icon-list-text">0127-601-2605</span>
                    </li>
                    <li>
                      <span className="icon-list-icon">E-MAIL:</span>
                      <span className="icon-list-text">admin@lrceg.com</span>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-lg-1 col-md-12"></div>
              <div className="col-lg-4 col-md-12">
                <h6 className="heading-title">ENQUIRE</h6>
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
        {/*Section 3*/}
        <div className="section-3">
          <div className="container text-center">
            <div className="row">
              <div className="col-sm-12">
                <Copyright title="MaisonCo" />
              </div>
              <div className="col-sm-12">
                <div className="social">
                  <a
                    href="https://instagram.com/lrc.eg?igshid=1itqg3tyu604p"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="screen-reader-text">Instagram</span>
                    <i className="fa fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/legacy-realestate-consultancies"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="screen-reader-text">linkedin</span>
                    <i className="fa fa-linkedin"></i>
                  </a>
                  <a
                    href="https://www.facebook.com/LRC.eg"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="screen-reader-text">Facebook</span>
                    <i className="fa fa-facebook"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  );
};

export default FooterOne;
