import Firebase from "../components/Firebase"

import React, { useEffect, useState } from 'react'
import LoadingBlock from'../components/common/loading/loading'

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading,setLoading]=useState(true);

  useEffect(() => {
    Firebase.auth.onAuthStateChanged((user) => {
      setCurrentUser(user)
      if(user){
        let userjson = JSON.parse(JSON.stringify(user));
        localStorage.setItem("authUser", JSON.stringify(userjson));
      }
      setLoading(false)
    });
  }, []);

 

  if (loading) {
    return <LoadingBlock />
  } else {
    return (
      <AuthContext.Provider value={{ currentUser}}>
        {children}
      </AuthContext.Provider>
    );
  }
};
