import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ScrollContext } from "react-router-scroll-4";
import "./index.scss";
import "animate.css/animate.min.css";

// Layouts
import Home1 from "./components/home-1";

// Pages
import PageNotFound from "./components/404";

// Features
import Layout from "./app";
import AboutPage from "./components/pages/about/about";
import ContactPage from "./components/pages/contact/contact";

import Admin from "./components/pages/Admin";
import AdminPanal from "./components/pages/AdminPanal";
import { AuthProvider } from "./hoc/Auth";
import PrivateRoute from "./hoc/PrivateRoute";

class Root extends React.Component {
  render() {
    return (
      <AuthProvider>
        <BrowserRouter basename={"/"}>
          <ScrollContext>
            <Switch>
              <Layout>
                <Route exact path={`/`} component={Home1} />
                <Route exact path={`/admin`} component={Admin} />
                <PrivateRoute
                  exact
                  path={`/adminpanal`}
                  component={AdminPanal}
                />
                <Route exact path={`/contact-us`} component={ContactPage} />
                <Route exact path={`/about-us`} component={AboutPage} />
                <Route path={"/404"} component={PageNotFound} />
                <Redirect path="/404" />
              </Layout>
            </Switch>
          </ScrollContext>
        </BrowserRouter>
      </AuthProvider>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));
