import React, { useEffect, useState } from "react";
import Firbase from "../Firebase";
import { useJsonToCsv } from "react-json-csv";
import { Col,Button, Container, Row, Table } from "react-bootstrap";
import Firebase from "../Firebase";

function AdminPanal() {
  const [data, setData] = useState([]);
  const [dataShow, setDataShow] = useState([]);
  const { saveAsCsv } = useJsonToCsv();
  const filename = "Csv-file",
    fields = {
      Name: "Name",
      Phone: "Phone",
      Email: "Email",
      Comment: "Comment",
    }
    // ,
    // style = {
    //   padding: "5px",
    // },
    // text = "Convert Json to Csv";

  const getLeadss = () => {
    Firbase.getLeads()
      .then((res) => {
        setData(res.docs.map(doc => doc.data()));
        setDataShow(res.docs)
      })
      .catch((err) => {
        alert(err);
      });
  };

  useEffect(() => {
    getLeadss();
  }, []);
  const HandleDelete= (id)=>{
    const answer = window.confirm("Delete Lead?");
    if (answer) {
      Firebase.deleteLead(id)
    }
    else {
        //some code
} 
    
  }

  return (
    <Container style={{marginBottom:"300px"}}>
     
      
      <Row >
        <Col  md={"auto"}>
        <h2>Panal</h2>
        <button   className={"btn-primary btn-block"} onClick={() => saveAsCsv({ data, fields, filename })}>
        DOWNLOAD
      </button>
        </Col>
        <Col md={9} style={{marginLeft:"0px",display:"flex",}}>
          <div style={{width:"100%"}}>
        <h2 style={{margin:"0 auto"}}>Leads</h2>
        <Table  striped bordered hover responsive className='table-sm'>
        <thead>
            <tr >
              <th><strong>Name</strong></th>
              <th><strong>Phone</strong></th>
              <th><strong>Email</strong></th>
              <th><strong>Comment</strong></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {dataShow.map((lead) => (
              <tr key={lead.id}>
                <td><h6>{lead.data().Name}</h6></td>
                <td><h6>{lead.data().Phone}</h6></td>
                <td><h6>{lead.data().Email}</h6></td>
                <td><h6>{lead.data().Comment}</h6></td>
                <td>
                  <Button  variant="danger" onClick={()=>HandleDelete(lead.id)}>Delete</Button></td>    
              </tr>  
            ) )}
          </tbody>
        </Table>
        </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminPanal;
