import React from "react";
import Breadcrumb from "../../common/breadcrumb/breadcrumb";
import { useForm } from "react-hook-form";
import Form from "react-validation/build/form";
import Firebase from "../../Firebase";
import Helmet from "react-helmet";
import "./contact.scss";

const ContactPage = () => {
  const { register, handleSubmit } = useForm();
  const onSubmit = (data) => {
    Firebase.createMsg(data)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        // alert(error)
      });
  };

  return (
    <div className={`contact-page-wrap`}>
      <Helmet>
        <title>Legacy | Contact Us</title>
      </Helmet>
      <Breadcrumb title={"Contact Us"} />
      <div className="section-wrapper">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="box-icon">
                <div className="icon">
                  <i className="opal-icon-phone"></i>
                </div>
                <div className="content">
                  <span className="title text-uppercase">PHONE :</span>
                  <p className="description">0127-601-2605</p>
                  {/* <p className="description">539-737-1380</p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="box-icon">
                <div className="icon">
                  <i className="opal-icon-point"></i>
                </div>
                <div className="content">
                  <span className="title text-uppercase">ADDRESS :</span>
                  <p className="description">116, 1st Neighborhood</p>
                  <p className="description">5th Settlement, New Cairo</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-4">
              <div className="box-icon no-border">
                <div className="icon">
                  <i className="opal-icon-envelope"></i>
                </div>
                <div className="content">
                  <span className="title text-uppercase">E-MAIL :</span>
                  <p className="description">admin@lrceg.com</p>
                  <p className="description">sales@lrceg.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Form onSubmit={handleSubmit(onSubmit)} className="section-wrapper">
        <div className="form-contact mb-5">
          <h3 className="text-uppercase text-center">Get in Touch !</h3>
          <div className="mt-4 row">
            <div className="col-12 mb-3">
              <textarea
                name="Comment"
                className="form-control"
                ref={register}
                placeholder="Your Message"
                rows="3"
              ></textarea>
            </div>
            <div className="col-6 mb-3">
              <input
                type="text"
                className="form-control"
                required
                name="Name"
                ref={register}
                placeholder="Your Name"
              />
            </div>
            <div className="col-6 mb-3">
              <input
                type="email"
                className="form-control"
                required
                name="Email"
                ref={register}
                placeholder="Your Email"
              />
            </div>
            <div className="col-12 mb-3">
              <input
                type="number"
                className="form-control"
                required
                name="Phone"
                ref={register}
                placeholder="Your Number"
              />
            </div>
            <div className="col-12">
              <button type="submit" className="btn btn-primary w-100">
                LEAVE A message <i className="opal-icon-arrow"></i>
              </button>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ContactPage;
