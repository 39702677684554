import React, { useState } from 'react';
import Form from 'react-validation/build/form';
import Firebase from "../../Firebase";
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import Textarea from 'react-validation/build/textarea'
import {isEmail, isEmpty, isMobilePhone} from 'validator';
import './contact-form.scss';

const ContactForm  =()=> {
 
    const [data,setData] = useState({
                Name: '',
                Phone: '',
                Email: '',
                Comment: ''
    })
    const required = (value) => {
        if (isEmpty(value)) {
            return <small className="form-text text-danger">This field is required</small>;
        }
    }

    const email = (value) => {
        if (!isEmail(value)) {
            return <small className="form-text text-danger">Invalid email format</small>;
        }
    }

    const phone = (value) => {
        if (!isMobilePhone(value)) {
            return <small className="form-text text-danger">Invalid Phone number format</small>;
        }
    }

    const onSubmit = (e) => {
        e.preventDefault();
        // this.form.validateAll();

        if (isMobilePhone(data.Phone) && isEmail(data.Email) && !isEmpty(data.Comment)) {
            // alert(' success!' + '\n name: ' + data.Name + '\n email: ' + data.Email + '\n phone:' + data.Phone + '\n content:' + data.Comment + '');
            Firebase.createMsg(data).then(()=>{
                window.location.reload()
            }).catch(error =>{
                // alert(error)
              })

        }
    }

        return (
            <Form className="form-group-v1" onSubmit={e => onSubmit(e)} >
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="name" onChange={(e)=>{setData( c => ({...c,Name:e.target.value}))}} value={data.Name} type="text" placeholder="Your name *" className="form-control" validations={[required]} required/>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="form-group">
                            <Input name="phone" onChange={(e)=>{setData( c => ({...c,Phone:e.target.value}))}}  value={data.Phone} type="text" placeholder="Phone number *" className="form-control" validations={[required, phone]}/>
                        </div>
                    </div>
                </div>

                <div className="form-group">
                    <Input name="email" onChange={(e)=>{setData( c => ({...c,Email:e.target.value}))}} value={data.Email} type="email" placeholder="Your e-mail *" className="form-control" validations={[required, email]}/>
                </div>

                <div className="form-group">
                    <Textarea onChange={(e)=>{setData( c => ({...c,Comment:e.target.value}))}}  value={data.Comment} className="form-control" placeholder="How can we help you ?" rows="3" validations={[required]}/>
                </div>
                <button type="submit" className="btn btn-primary mt-4 btn-block">Submit</button>
                <CheckButton style={{display: 'none'}} />
            </Form>
        )
}

export default ContactForm