import React, { useEffect, useState } from "react";

// Import custom components
import MainMenu from "./common/navbar";

import { Button } from "react-bootstrap";
import Firebase from "../../Firebase";
import { Link } from "react-router-dom";

const HeaderOne = ({ logoName }) => {
  const [admin, setAdmin] = useState("");

  const sginOutHandler = () => {
    Firebase.logout().then(() => {
      window.location.reload();
    });
  };

  const adminHeader = () => {
    switch (admin) {
      case "/admin":
        return admin ? <div></div> : <MainMenu />;

      case "/adminpanal":
        return (
          <div>
            <Button variant="light" size="md" onClick={() => sginOutHandler()}>
              <h6>SignOut</h6>
            </Button>
          </div>
        );
      default:
        return <MainMenu />;
    }
  };

  useEffect(() => {
    setAdmin(window.location.pathname);
  }, [window.location.pathname]);

  return (
    <header id="masthead" className="site-header">
      <div className="container">
        <div className="row">
          <div className="d-flex align-items-center w-100 site-header-wrap justify-content-between">
            <div className="site-branding" style={{width: "120px"}}>
              <Link to={`/`} className="custom-logo-link">
                <span className="screen-reader-text">
                  Legacy
                </span>
                <img src={`/assets/images/logo.svg`} alt="" />
              </Link>
            </div>

            {adminHeader()}
            <div className="header-group d-flex">
              <div className="rpm-trigger header-button" id="rpm-trigger">
                <i className={`opal-icon opal-icon-toggle`}></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderOne;
