import React, { Component } from "react";
import "./ourservices.scss";
import Heading from "../../common/heading/heading";

class OurServicesSection extends Component {
  render() {
    return (
      <div className="ourservices">
        <div className="container">
          <Heading>Our Services</Heading>
          <div className="row">
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i className="opal-icon-map"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Flexible Assistant to found you the best home
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      We will assist you, from viewing available properties,
                      through consultation and negotiations, to closing, and
                      follow-up. We deal with each client on a personal,
                      customized approach in accordance to your level of
                      expertise with the purchasing process and your personal
                      tastes. We operate using a team approach, which means,
                      that you will not only have a dedicated agent available
                      but administrative support as well.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 d-flex">
              <div className="featured-box style-2">
                <div className="featured-box-wrap">
                  <div className="featured-box-top">
                    <div className="featured-box-icon">
                      <i className="opal-icon-map"></i>
                    </div>
                    <h3 className="featured-box-title text-uppercase">
                      Personal and Tailor-Made assistant
                    </h3>
                  </div>
                  <div className="featured-box-bottom">
                    <p className="featured-box-description">
                      We deal with each client on a personal, customized
                      approach in accordance to your level of expertise with the
                      purchasing process and your personal tastes. We operate
                      using a team approach, which means, that you will not only
                      have a dedicated agent available but administrative
                      support as well. In addition to searching for real estate
                      properties, we can offer the discerning client
                      comprehensive information on the purchasing process,
                      comparable properties, market trends, area communities and
                      developments, buyer representation, purchasing
                      requirements, investor resources, and additional
                      resources.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OurServicesSection;
